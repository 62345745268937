.dropdown-menu{
    box-shadow: $dropdown-box-shadow;
}
.dropdown-item{
    border-radius: $dropdown-border-radius;
    transition: all .25s ease;
    text-transform: $dropdown-item-text-transform;
    &:not(:last-child){
        margin-bottom: .25rem;
    }
}
.dropdown-menu[data-popper-placement^=right],.dropdown-menu[data-popper-placement^=top],.dropdown-menu[data-popper-placement^=left]{
    top: auto !important;
    animation: none !important;
}  

.navbar-expand {
    @each $breakpoint in map-keys($grid-breakpoints) {
      $next: breakpoint-next($breakpoint, $grid-breakpoints);
      $infix: breakpoint-infix($next, $grid-breakpoints);
  
      // stylelint-disable-next-line scss/selector-no-union-class-name
      &#{$infix} {
        @include media-breakpoint-up($next) {
            .dropdown-menu{
                padding: $dropdown-padding-y * 0 $dropdown-padding-x * 0;
                display:block;
                opacity:0;
                visibility: hidden;
                transition: all .2s;
                transform: translateY(10px);
                top:100%;
                &.dropdown-menu-end{
                    right:0;
                }
            }
            .dropdown:hover .dropdown-menu{
                opacity: 1;
                visibility: visible;
                transform: none;
            }
            .dropdown-menu.dropdown-menu-full{
                width: calc(100% - var(--bs-gutter-x));
                left:calc(var(--bs-gutter-x) * 0.5);
            }
        }
    }
}
}
.navbar-expand {
    @each $breakpoint in map-keys($grid-breakpoints) {
      $next: breakpoint-next($breakpoint, $grid-breakpoints);
      $infix: breakpoint-infix($next, $grid-breakpoints);
  
      // stylelint-disable-next-line scss/selector-no-union-class-name
      &#{$infix} {
        @include media-breakpoint-down($next) {
            .dropdown-menu{
                box-shadow: none;
            }
        }
    }
}
}