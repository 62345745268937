.price1 {
    font-size: 2.5rem;
    color: $green;
    font-weight:700;
}
.price2 {
    color: $indigo;    
}

.price3 {
    color:$primary;
}

.price-border{
    border:solid 2px $indigo;
}
.p-top{
padding-top: 6rem!important;
}
.p-bottom{
    padding-bottom: 3rem!important;
}
.sp-text{
color: $primary;
}
.caa{
 background-color:$indigo-400;
}
.m-top{
    margin-top: -3rem!important;
}
.m-top-cf{
    margin-top: -0.5rem!important;
    margin-bottom: 3em!important;
}
.m-right{
    margin-right: 90px;
}
.d-logo{
    font-size: 1.35rem;
    font-weight: 700;
    color: $primary;
}
.d-logo-footer{
    font-size: 1.75rem;
    font-weight: 700;
    color: $primary;    
}
.d-logo-footer:hover{ 
    color: $primary;    
}
.d-logo:hover{
    color: shift-color($link-color, $link-shade-percentage);
    transition: $nav-link-transition;    
}
.navbar-brand {    
    margin-left: 20px;
}
body.dark-mode{
    .d-logo {
        color: $indigo-400;
    }
    .d-logo:hover {
        color: $indigo-300;
    }
    .d-logo-footer{
        color: $indigo-400;        
    }
    .d-logo-footer:hover{
        color: $indigo-400;        
    }
}
.mb-5d {
    margin-bottom: 3.6rem!important;
}
.mb-3d {
    margin-bottom: 0.8rem!important;
}

//.mb-mcs {
//margin-bottom: 3.0rem!important;
//}

.mb-3lt {
    margin-bottom: 2.4rem!important;
}
.dc-img {
    @media (min-width: 992px) {
        margin-left: 100px;
    }    
}
.info {
    display: block;
    --bs-btn-hover-color: #5bdf91;
    --bs-btn-hover-bg: transparent;
    pointer-events: none;
    margin-bottom: 15px;   
    text-transform: none;
}
.btrotate{
    rotate:180deg;
}

.price-h01 {
    @extend h1;
}

.price-h03 {
    @extend h3;
}
@media (max-width: 575px) {
    .pt-sm-3 {
      padding-top: 5rem; 
    }
  }